import * as React from 'react';
import { format_data } from '../../utils/functions';
import ButtonBackup from "../../components/Gestao/Button/ButtonBackup";


export default function RowAcessos({ row, marca, click, load }) {
  
  let arr = {}
  row.split(" - ").forEach(async item => {
    let foo = item.split(" ");
    let chave = foo[0];
    if (arr[chave]) {
      arr[chave] += ` - ${foo[1]}`;
    } else {
      arr[chave] = foo[1];
    }
  });

  const catalogo = arr["CATALOGO"].split(" - ").reverse();
  const skus = arr["SKUS"].split(" - ").reverse();
  const atributos = arr["ATRIBUTOS"].split(" - ").reverse();


  return (<>
    <tr>
      <td className=''> CATALOGO </td>
      <td className='center-buttons'>
        <ButtonBackup texto={format_data(catalogo[2])} click={click} load={load} id={`${marca}/CATALOGO/${catalogo[2]}`} />
        <ButtonBackup texto={format_data(catalogo[1])} click={click} load={load} id={`${marca}/CATALOGO/${catalogo[1]}`} />
        <ButtonBackup texto={format_data(catalogo[0])} click={click} load={load} id={`${marca}/CATALOGO/${catalogo[0]}`} />
      </td>
    </tr>

    <tr>
      <td className=''> SKUS </td>
      <td className='center-buttons'>
        <ButtonBackup texto={format_data(skus[2])} click={click} load={load} id={`${marca}/SKUS/${skus[2]}`} />
        <ButtonBackup texto={format_data(skus[1])} click={click} load={load} id={`${marca}/SKUS/${skus[1]}`} />
        <ButtonBackup texto={format_data(skus[0])} click={click} load={load} id={`${marca}/SKUS/${skus[0]}`} />
      </td>
    </tr>

    <tr>
      <td className=''> ATRIBUTOS </td>
      <td className='center-buttons'>

        <ButtonBackup texto={format_data(atributos[2])} click={click} load={load} id={`${marca}/ATRIBUTOS/${atributos[2]}`} />
        <ButtonBackup texto={format_data(atributos[1])} click={click} load={load} id={`${marca}/ATRIBUTOS/${atributos[1]}`} />
        <ButtonBackup texto={format_data(atributos[0])} click={click} load={load} id={`${marca}/ATRIBUTOS/${atributos[0]}`} />
      </td>
    </tr>

  </>);
}
