import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

export const ProtectedRouteAdmin = ({ children }) => {
  const user = useContext(UserContext);

  if ((user.id_usuario == "34") || (user.id_usuario == "213") || (user.id_usuario == "258")|| (user.id_usuario == "20")) {
    return children;
  } else {
    return <Navigate to="/gestao" />;
  }
};
