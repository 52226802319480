import * as React from 'react';
import { useState, useEffect } from "react";
import Dropdown from "./../Dropdown/Dropdown";
import { extrairColunas, filtrarDados, montarArray, ordenarDados } from "./../../../utils/functions";
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Row from "./Row";
import "./Tabela.css"

export default function Tabela({ rows }) {

  const [dados, setDados] = useState(rows);
  const [dadosOriginal, setDadosOriginal] = useState(rows);
  const [valorEstoqueOrder, setValorEstoqueOrder] = useState('desc');
  const [dataPrevistaOrder, setDataPrevistaOrder] = useState('desc');
  const [campoOrder, setCampoOrder] = useState(false);
  const [status, setStatus] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [filtroFiliais, setFiltroFiliais] = useState([]);
  const [filtroStatus, setFiltroStatus] = useState([]);

  useEffect(() => { extrairColunas(dados, setStatus, setFiliais); }, []);


  const filtrar = async (e) => {

    const valor = e.target.value;
    if (typeof valor == 'undefined') { return }

    const campo = e.target.getAttribute('tipo').toUpperCase();
    let array = null;

    switch (campo) {
      case 'LOCAL':
        array = await montarArray(valor, filtroFiliais);
        setFiltroFiliais(array);
        break;
      case 'STATUS':
        array = await montarArray(valor, filtroStatus);
        setFiltroStatus(array);
        break;
      default:
        break;
    }

    if (filtroFiliais.length == 0 && filtroStatus.length == 0) {
      setDados(dadosOriginal)
    } else {
      let foo = [];
      foo = await filtrarDados(dadosOriginal, filtroFiliais, "FILIAL")
      foo = await filtrarDados(foo, filtroStatus, "FOTO")
      if (foo.length > 0) { setDados(foo) }
    }
  };

  const sortValorEstoque = async (e) => {
    setCampoOrder('VALOR')
    e.preventDefault();
    dados.sort(function (a, b) { return valorEstoqueOrder == 'desc' ? a.VALOR_ESTOQUE - b.VALOR_ESTOQUE : b.VALOR_ESTOQUE - a.VALOR_ESTOQUE; })
    setDados(dados)
    setValorEstoqueOrder(valorEstoqueOrder == 'desc' ? 'asc' : 'desc');
  }

  const sortDataPrevista = async (e) => {
    setCampoOrder('DATA')
    e.preventDefault();
    dados.sort((a, b) => {
      let dataA = new Date(a.data_programada);
      let dataB = new Date(b.data_programada);
      return dataPrevistaOrder == 'desc' ? dataA - dataB : dataB - dataA;
    })
    setDados(dados)
    setDataPrevistaOrder(dataPrevistaOrder == 'desc' ? 'asc' : 'desc');
  }


  const removerFiltros = async (e) => {
    let foo;
    if (e.toUpperCase() == "STATUS") {
      setFiltroStatus([]);
      foo = await filtrarDados(dadosOriginal, filtroFiliais, "FILIAL")
      foo = await filtrarDados(foo, [], "FOTO")
    } else if (e.toUpperCase() == "LOCAL") {
      setFiltroFiliais([]);
      foo = await filtrarDados(dadosOriginal, [], "FILIAL")
      foo = await filtrarDados(foo, filtroStatus, "FOTO")
    }
    setDados(foo)
  }

  return (
    <div className="tabela-responsiva">
      <div >
        <table>
          <thead>
            <tr className="header-tabela">
              <th className='referencia-coluna'>Referências ({dados ? dados.length : ""})</th>
              <th className='white-space-no'>
                <div className='valor-estoque'> Valor Estoque
                  <a className='sort' onClick={sortValorEstoque} > {campoOrder == 'VALOR' ? valorEstoqueOrder == "desc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon /> : ""} <SortRoundedIcon />
                  </a>
                </div>
              </th>

              <th >Tipo de Produto</th>
              <th style={{ "width": "220px" }} ><Dropdown filtrar={filtrar} titulo={'Local'} itens={filiais} filtro={filtroFiliais} removerFiltros={removerFiltros}> Local </Dropdown> </th>
              <th ><Dropdown filtrar={filtrar} titulo={'Status'} itens={status} filtro={filtroStatus} removerFiltros={removerFiltros}> Status </Dropdown> </th>
              <th className='white-space-no'>Data Solicitação do Estúdio</th>
              <th className='white-space-no'>Data Solicitação Foto</th>

              <th className='white-space-no'>
                <div className='valor-estoque'> Data Prevista
                  <a className='sort' onClick={sortDataPrevista} > {campoOrder == 'DATA' ? dataPrevistaOrder == "desc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon /> : ""} <SortRoundedIcon />
                  </a>
                </div>
              </th>

            </tr>
          </thead>
          <tbody>
            {dados && dados.length > 0 && dados.map((iten) => { return <Row row={iten} />; })}
          </tbody>
        </table>
      </div>
    </div>
  )
}