import { useEffect } from "react";
import Tabela from "../../../components/Estudio/Tabela/TabelaEstudio.js";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import ModalMessage from "../../../components/Estudio/ModalMessage/ModalMessage.js";
import ModalDatapicker2 from "../../../components/Estudio/ModalPicker/ModalDatapicker2";
import TabelaUpload from "../../../components/Estudio/Tabela/TabelaUpload.js";
import BotaoExportar from "../../../components/Estudio/BotaoExportar/BotaoExportar";
import { Skeleton } from "@mui/material";

export default function TopoTabsTabela({ value, alternarTabs, exportCsv, IosShareTwoToneIcon, salvarDataEnvioEmail, salvarDataSessaoFoto, salvarComentario, dados, busca }) {


    useEffect(() => {
    }, []);

    return (

        <div className="tab-estudio">
            <Box sx={{ width: "100%" }}>
                <TabContext value={value}>
                    <Box className="flex-tab">
                        <div className="tab-mobile">
                            <TabList onChange={alternarTabs} aria-label="tabelas">
                                <Tab label="Tabela Estudio" value="1" />
                                <Tab label="Tabela Upload" value="2" />
                                <div className="btn-exportar-mobile">
                                    <BotaoExportar
                                        className="btn-exportar"
                                        onClick={exportCsv}
                                        title={""}
                                        endIcon={<IosShareTwoToneIcon />}
                                    ></BotaoExportar>
                                </div>
                            </TabList>
                        </div>
                        <div className="box-tabs icones-esconder">
                            <div className="data-picker-email">
                                <div className="btn-enviarEmail icones-cinza">
                                    <ForwardToInboxRoundedIcon
                                        onClick={salvarDataEnvioEmail}
                                        className="foward-toinbox"
                                    />
                                </div>
                                <div className="btn-dataPricker icones-cinza">
                                    <ModalDatapicker2
                                        salvarDataSessaoFoto={salvarDataSessaoFoto}
                                        tab={value}
                                    />
                                </div>
                                <div className="btn-comentario icones-cinza">
                                    <ModalMessage
                                        salvarComentario={salvarComentario}
                                        tab={value}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                    {!dados ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} /> ) : (<>
                        <TabPanel value="1">
                            <Tabela rows={busca ? busca.buscaEstudio : dados.produtosEstudio.listagem} />
                        </TabPanel>
                        <TabPanel value="2">
                            <TabelaUpload rows={busca ? busca.buscaGestao : dados.produtosGestao} />
                        </TabPanel>
                    </>)}
                </TabContext>
            </Box>
        </div>
    )
}