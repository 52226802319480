import { createContext, useState } from "react";
import { marcasUtilizadas, tokenSomaEstudio } from "../utils/functions";
import { useNavigate } from "react-router";
import { setSessionStorage, removeSessionStorage, getSessionStorage } from "./../utils/sessionStorage";
import axios from "axios";
import conf from "../../src/utils/.params.js";

export const UserContext = createContext();

export const UserStorage = ({ children }) => {
  const [user, setUser] = useState(getSessionStorage("user") || null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();


  async function removerStorages(dataUser) {
    sessionStorage.removeItem('params')
    sessionStorage.removeItem(`refSelecionadasPacote_${dataUser.marcaSelecionada}`)
    sessionStorage.removeItem(`refSelecionadasPacoteUpload_${dataUser.marcaSelecionada.marcaSelecionada}`)
    sessionStorage.removeItem('pacoteOriginal')
    sessionStorage.removeItem('pacote')

  }

  async function login(usuario, senha, setError, setLoad, setTextError) {

    try {


      if (usuario === "" || senha === "") {
        setError(true);
        setTextError("Preencha todos os campos");
        return;
      }
      setLoad(true);

      let reqOptions = {
        url: conf.url() + "/login",
        method: "POST",
        data: { usuario: usuario, senha: senha },
      };

      let ret = await axios.request(reqOptions);
      let retMarcas = await marcasUtilizadas(ret.data.marcas);
      // let tokenEstudio = (await tokenSomaEstudio()).replaceAll('"', '');

      const dataUser = {
        id_usuario: ret.data.id_usuario,
        email: ret.data.usuario,
        name: ret.data.name,
        app: "Farol",
        marcasPermitidas: retMarcas,
        marcaSelecionada: retMarcas[0],
        tokenFarol: ret.data.token
        // tokenEstudio: tokenEstudio,
      };

      setLoad(false)
      setError(false);
      removerStorages(dataUser);
      setSessionStorage("user", dataUser);
      setUser(dataUser);
      navigate("/performance");

    } catch (error) {
      console.log(error.message)
      setLoad(false);
      setError(true);
      setTextError("E-mail e/ou senha incorretos!");
      return;
    }
  }

  async function userLogout() {
    setUser(null);
    removeSessionStorage("user");
    setLoading(false);
    navigate("/login");
  }

  let value = { ...user, loading, error, login, setError, userLogout };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
