import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./globalStyle.css";
import { UserStorage } from "./contexts/UserContext";
import { ProtectedRoute } from "./components/Helper/ProtectedRoute";
import { ProtectedRouteAdmin } from "./components/Helper/ProtectedRouteAdmin";
import { ProtectedRouteMaster } from "./components/Helper/ProtectedRouteMaster";
import SessionTimeout from "./components/Session/SessionTimeout";
import EstudioPrincipal from "./pages/Estudio/Principal/principal";
import Login from "./pages/FarolLogin/login";
import Sucesso from "./pages/FarolLogin/sucesso";
import Recuperar from "./pages/FarolLogin/recuperar";
import GestaoPrincipal from "./pages/Gestao/Principal/principal";
import NaoOfertados from "./pages/Gestao/NaoOfertados/naoOfertados";
import ProdutosMarketplace from "./pages/Gestao/ProdutosMarketplace/produtosMarketplace";
import Gamefication from "./pages/Gamefication/gamefication";
import Ofertados from "./pages/Gestao/Ofertados/ofertados";
import Pacote from "./pages/Estudio/Pacote/pacote";
import Acessos from "./pages/Log/acessos";
import Atualizacoes from "./pages/Log/atualizacoes";
import Visiveis from "./pages/Log/visiveis";
import Marketplace from "./pages/Log/marketplace";
import ResumoDiario from "./pages/Log/resumoDiario";
import Scraping from "./pages/Log/scraping";
import AtrasoOfertados from "./pages/Log/atrasoOfertados";
import Backup from "./pages/Log/listarBackup";
import FotosInicio from "./pages/Fotos/Inicio/index";
import FotosSemTratamento from "./pages/Fotos/SemTratamento/index";
import FotosEmTratamento from "./pages/Fotos/EmTratamento/index";
import FotosTratadas from "./pages/Fotos/Tratadas/index";
import FotosPendentes from "./pages/Fotos/PendentesUpload/index";
import FotosUpload from "./pages/Fotos/Upload/index";
import FotosBackup from "./pages/Fotos/Backup/index";
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (<>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
    <BrowserRouter>
      <UserStorage>
        <SessionTimeout />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/recCount" element={<Recuperar />} />
          <Route path="/solicSuccess" element={<Sucesso />} />

          <Route path="/performance" element={<ProtectedRoute><Gamefication /></ProtectedRoute>} />

          <Route path="/estudio" element={<ProtectedRoute> <EstudioPrincipal /> </ProtectedRoute>} />
          <Route path="/estudio/pacote" element={<ProtectedRoute> <Pacote /> </ProtectedRoute>} />

          <Route path="/" element={<ProtectedRoute> <GestaoPrincipal /> </ProtectedRoute>} />
          <Route path="/gestao" element={<ProtectedRoute> <GestaoPrincipal /> </ProtectedRoute>} />
          <Route path="/gestao/naoOfertados" element={<ProtectedRoute> <NaoOfertados /> </ProtectedRoute>} />
          <Route path="/gestao/ofertados" element={<ProtectedRoute> <Ofertados /> </ProtectedRoute>} />
          <Route path="/gestao/produtosMarketplace" element={<ProtectedRoute> <ProdutosMarketplace /> </ProtectedRoute>} />


          <Route path="/fotos/inicio" element={<ProtectedRoute> <FotosInicio /> </ProtectedRoute>} />
          <Route path="/fotos/sem-tratamento" element={<ProtectedRoute> <FotosSemTratamento /> </ProtectedRoute>} />
          <Route path="/fotos/em-tratamento" element={<ProtectedRoute> <FotosEmTratamento /> </ProtectedRoute>} />
          <Route path="/fotos/tratadas" element={<ProtectedRoute> <FotosTratadas /> </ProtectedRoute>} />
          <Route path="/fotos/pendentes-upload" element={<ProtectedRoute> <FotosPendentes /> </ProtectedRoute>} />
          <Route path="/fotos/upload" element={<ProtectedRoute> <FotosUpload /> </ProtectedRoute>} />
          <Route path="/fotos/backup" element={<ProtectedRoute> <FotosBackup /> </ProtectedRoute>} />

          <Route path="/log/visiveis" element={<ProtectedRoute> <Visiveis /> </ProtectedRoute>} />
          <Route path="/log/marketplace" element={<ProtectedRoute> <Marketplace /> </ProtectedRoute>} />
          <Route path="/log/resumo-diario" element={<ProtectedRoute> <ResumoDiario /> </ProtectedRoute>} />
          <Route path="/log/scraping" element={<ProtectedRoute> <Scraping /> </ProtectedRoute>} />
          <Route path="/log/atraso-ofertados" element={<ProtectedRoute> <AtrasoOfertados /> </ProtectedRoute>} />

          <Route path="/log/acessos" element={<ProtectedRouteAdmin> <Acessos /> </ProtectedRouteAdmin>} />
          <Route path="/log/atualizacoes" element={<ProtectedRouteAdmin> <Atualizacoes /> </ProtectedRouteAdmin>} />
          
          <Route path="/backup" element={<ProtectedRouteAdmin> <Backup /> </ProtectedRouteAdmin>} />

        </Routes>
      </UserStorage>
    </BrowserRouter>
  </>);
};

export default App;
