import axios from "axios";
import conf from "../../src/utils/.params.js";
import { getSessionStorage, removeSessionStorage } from "../../src/utils/sessionStorage";
import SendGTAGEvent from '../utils/sendGTAGEvent';
import { toast } from 'react-toastify';
import ANIMALE from '../static/gameficationBrands/Animale.png';
import CRIS_BARROS from '../static/gameficationBrands/CrisBarros.png';
import FABULA from '../static/gameficationBrands/Fabula.png';
import FARM from '../static/gameficationBrands/Farm.png';
import FARM_GLOBAL from '../static/gameficationBrands/FarmGlobal.png';
import FOXTON from '../static/gameficationBrands/Foxton.png';
import MARIA_FILO from '../static/gameficationBrands/MariaFilo.png';
import NV from '../static/gameficationBrands/NV.png';
import OFF_PREMIUM from '../static/gameficationBrands/Off.png';
import { saveAs } from 'file-saver';
import Resizer from "react-image-file-resizer";


const user = getSessionStorage("user")
const marca = user ? (getSessionStorage("user").marcaSelecionada).replace(/ /g, "") : false;

const brandLogos = { ANIMALE: ANIMALE, BYNV: NV, CRISBARROS: CRIS_BARROS, FABULA: FABULA, FARM: FARM, FOXTON: FOXTON, MARIAFILO: MARIA_FILO, OFF: OFF_PREMIUM, FARM_GLOBAL: FARM_GLOBAL };

export const limparNomeFoto = async (texto) => {
  return await texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/ /g, "_");
}

export const resizeFile = async (file, resolution) => {
  return new Promise((resolve) => { Resizer.imageFileResizer(file, resolution, resolution, "JPEG", 80, 0, (uri) => { resolve(uri); }, "file"); });
}

export const filtroErroSucesso = async (data, tipo) => {

  return data.filter(item => {
    if (item.hasOwnProperty('path')) {
      return item.path.indexOf(tipo) != -1;
    } else {
      return item.url.indexOf(tipo) != -1;
    }
  })
}

export const drag = (ev) => {

  const marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");
  var path = ev.target.getAttribute('path') ? ev.target.getAttribute('path') : ev.target.getAttribute('src')
  path = path.replace(`https://storage.googleapis.com/${getBucketName(marca)}/`, "")
  ev.dataTransfer.setData("path", path);
}

export const drop = async (ev, setModif) => {
  const user = getSessionStorage("user");
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  ev.preventDefault();
  const pathOrigem = ev.dataTransfer.getData("path");
  const pathDestino = ev.target.getAttribute('path');
  if (pathDestino == pathOrigem) {
    return
  }
  const idToast = toast.loading("Aguarde um pouco...")

  const data = { "pathOrigem": pathOrigem, "pathDestino": pathDestino };
  let config = { method: 'put', url: `${conf.url_fotos()}/pictures/move/${await id_marca(marca)}`, headers: {Authorization: "Bearer " + user.tokenFarol }, data: data };

  await axios.request(config).then(result => {
    toast.update(idToast, { render: "Itens movidos com sucesso!", type: "success", isLoading: false, autoClose: 3000 });
    setModif(Math.random(9999))
  }).catch(error => {
    toast.update(idToast, { render: "Erro ao mover!", type: "error", isLoading: false, autoClose: 5000 });
    console.log(error);
  });

}

export const allowDrop = (ev) => { ev.preventDefault(); }

export const buscaGeral = (termo, setPastasSelecionadas, setBusca, setLoading, tipoBusca) => {

  if (termo) {
    setLoading(false);
    setBusca(false);
    if (tipoBusca == 'FOTOS') {
      if (setPastasSelecionadas) {
        setPastasSelecionadas([])
      }
      requestSomaStudioBusca(termo.trim(), setBusca, setLoading);
    } else {
      defaultRequest('/buscaProdutos', { referencia: termo, tipoTela: tipoBusca }).then(function (response) { setBusca(response.dados) });
    }
  }

}

export const diretorioOrigem = async (item) => {
  if (item.hasOwnProperty('path')) {
    item = item.path;
  }

  return `${item.split("/").shift()}/`;
}

export const getBucketName = (marca) => {

  let foo = {
    "ANIMALE": "soma_estudio_animale",
    "FARM": "soma_estudio_farm",
    "FABULA": "soma_estudio_fabula",
    "OFF": "soma_estudio_off",
    "FOXTON": "soma_estudio_foxton",
    "CRISBARROS": "soma_estudio_crisbarros",
    "MARIAFILO": "soma_estudio_mariafilo",
    "BYNV": "soma_estudio_nv",
    "FARMGLOBAL": "soma_estudio_farm_global"
  }
  return foo[marca]

}

export const validInputAtivo = (id, setAtivo) => {
  const el = document.getElementById(id).value;
  el.length > 0 ? setAtivo(true) : setAtivo(false)
}

export const qtdSelecionadas = (stateRefSelecionadas, pastasSelecionadas) => {
  if (!stateRefSelecionadas) {
    stateRefSelecionadas = 0;
  }
  return parseInt(stateRefSelecionadas) + parseInt(pastasSelecionadas.length)
}

export const requestSomaStudioBusca = async (termoBusca, setBusca, setLoading) => {
  const user = getSessionStorage("user");
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  let config = {
    method: 'get',
    url: `${await conf.url_fotos()}/pictures/search/${await id_marca(marca)}?termoBusca=${termoBusca}`,
    headers: { Authorization: "Bearer " + user.tokenFarol }
  };

  let retorno = await axios.request(config);
  if (retorno.status == 200) {
    if (retorno.data.paths.length != 0 || retorno.data.pictures.length != 0) {
      setBusca({ dados: retorno.data, termoBusca: termoBusca });
    }
  }
  setLoading(true)
}

export const montarTelaFotosBackup = async (dados, diretorioAtual, setFotos, setDiretorios) => {

  let dir = []
  let fotos = []

  dados.map(item => {

    if (item.hasOwnProperty('url')) {
      if (diretorioAtual.join("/") == item.path) {
        fotos.push(item);
      }

    } else if (item.hasOwnProperty('path')) {
      let dif = item.path.split("/").filter(x => !diretorioAtual.includes(x));
      if (dif[0]) {

        if ((diretorioAtual.length == 1) || (item.path.indexOf(diretorioAtual.join("/")) != -1)) {
          dir.push(dif[0])
        }
      }

    }

  })

  setFotos(await agruparProdutosFotos([...new Set(fotos)]))
  setDiretorios([...new Set(dir)]);

}

export const montarTelaFotos = async (dados, diretorioAtual, setFotos, setDiretorios) => {

  let dir = []
  let fotos = []

  dados.map(item => {

    if (item.hasOwnProperty('url')) {
      if (diretorioAtual.join("/") == item.path) { fotos.push(item); }

    } else if (item.hasOwnProperty('path')) {

      let dif = item.path.split("/").filter(x => !diretorioAtual.includes(x));

      if (dif[0]) {
        if ((diretorioAtual.length == 1) || (item.path.indexOf(diretorioAtual.join("/")) != -1)) {
          dir.push(dif[0])
        }
      }

    }

  })

  setFotos(await agruparProdutosFotos([...new Set(fotos)]))
  setDiretorios([...new Set(dir)]);

}

export const todasFotosTelaUpload = async (ret, todos, setTodos, setErros, setUpload) => {
  await ret.data.map(async (element) => {
    console.log(element)

    if (element.status == "upload_erros") {
      let err = await agruparProdutosFotos(element.data)
      setErros(err);

      if (todos.imagens.length != 0) {
        todos.imagens = todos.imagens.map((element) => {
          let index = err.imagens.findIndex(item => { return item.reference == element.reference })

          if (index != -1) {
            element.imagens = element.imagens.concat(err.imagens[index].imagens)
          }

          return element;
        });
      } else {
        todos.imagens.concat(err.imagens);
      }

      todos.qtdFotos += err ? err.qtdFotos : 0;
      todos.qtdReferencias += err ? err.qtdReferencias : 0;
      setTodos(todos)
    }

    if (element.status == "upload_enviadas") {
      let succ = await agruparProdutosFotos(element.data)
      setUpload(succ);

      if (todos.imagens.length != 0) {
        todos.imagens = todos.imagens.map((element) => {
          let index = succ.imagens.findIndex(item => { return item.reference == element.reference })
          if (index != -1) {
            element.imagens = element.imagens.concat(succ.imagens[index].imagens)
          }

          return element;
        });
      } else {
        todos.imagens.concat(succ.imagens);
      }

      todos.qtdFotos += succ ? succ.qtdFotos : 0;
      todos.qtdReferencias += succ ? succ.qtdReferencias : 0;
      setTodos(todos)
    }
  });
}

export const handlerOpenModalDetalheFoto = async (e, setFotosModalDetalhe, setOpen) => {


  let novo = [];
  fotos.imagens.map(item => { if (item.reference == e) { novo.push(item.imagens) } })
  setFotosModalDetalhe(novo)
  setOpen(true)

  // let fotos = dados.imagens.filter(item => { return item.imagens.some((elem) => elem.nome == e) })
  // setFotosModalDetalhe(fotos)
  // setOpen(true)
}

export const id_marca = async (marca) => {
  marca = marca.replaceAll(" ", "").replaceAll("_", "");
  let foo = {
    ANIMALE: "1",
    FARM: "2",
    FABULA: "5",
    OFF: "6",
    FOXTON: "7",
    CRISBARROS: "9",
    MARIAFILO: "15",
    BYNV: "16",
    FARMGLOBAL: "100",
  }
  return foo[marca];
}

export const agruparProdutosFotos = async (array) => {

  if (array.length == 0) { return false; }

  var occurrences = {};
  var qtdReferencias = [];
  var qtdFotos = 0;

  let foo = await array.filter(function (x, key) {
    let chave = x.reference;

    if (chave && !qtdReferencias.includes(chave)) { qtdReferencias.push(chave) }
    if (x.hasOwnProperty('url')) { qtdFotos++; }

    let obj = { url: x.url, reference: x.reference, name: x.name, path: x.path }

    if (occurrences[chave] || occurrences[chave] == 0) {
      array[occurrences[chave]].imagens.push(obj);
      return false;
    } else {
      occurrences[chave] = key;
      array[occurrences[chave]].imagens = [obj];

      delete x.color;
      delete x.product;
      delete x.status;
      delete x.type;
      delete x.path;
      return true;
    }
  });

  return { qtdReferencias: qtdReferencias.length, qtdFotos: qtdFotos, imagens: foo }
}

export const countImagens = async (array) => {
  let valid_referencias = [];
  let valid_fotos = [];
  array.data.forEach(el => {

    if (el.hasOwnProperty('reference') && !valid_referencias.includes(el.reference)) {
      valid_referencias.push(el.reference);
    }
    if (el.hasOwnProperty('url') && !valid_fotos.includes(el.url)) {
      valid_fotos.push(el.url);
    }
  });

  return { qtdReferencias: valid_referencias.length, qtdFotos: valid_fotos.length }
}

export const camelCaseTransform = async (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const tokenSomaEstudio = async () => {

  let config = {
    method: 'post', url: conf.url_fotos() + '/sessions',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(conf.credenciais_soma_studio())
  };
  return axios.request(config).then((response) => { return JSON.stringify(response.data.token) }).catch((error) => { console.log(error.message) });
}

export const requestSomaStudio = async (route) => {
  const user = getSessionStorage("user");
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  let pasta = (window.location.pathname).split("/").reverse();
  pasta = await nomePasta(pasta[0]);

  let config = {
    method: 'post',
    url: `${await conf.url_fotos()}${route}/${await id_marca(marca)}?status=${pasta}`,
    headers: { Authorization: "Bearer " + user.tokenFarol }
  };

  let retorno = await axios.request(config);
  if (retorno.status == 200) {
    return retorno.data;
  } else {
    return false;
  }
}

export const handleClickFotosProduto = (e) => {
  const checked = e.target.checked
  let elementos = document.getElementsByName(e.target.name);
  for (const el of elementos) {
    let check = el.checked;
    if ((checked && !check) || (!checked && check)) {
      el.click();
    }
  }
}

export const handleClickTodasFotos = (e) => {

  const checked = e.target.checked
  let elementos = document.querySelectorAll(`.${e.target.value}`);

  for (const el of elementos) {

    // seleção do checkbox da linha de ref_cor
    let em_branco = document.getElementById(`${el.id}_not_check`);
    let selecao = document.getElementById(`${el.id}_check`);
    if (checked) {
      el.setAttribute("checked", true);
      em_branco.classList.add("displayNone");
      selecao.classList.remove("displayNone");
    } else {
      el.removeAttribute("checked");
      selecao.classList.add("displayNone");
      em_branco.classList.remove("displayNone");
    }

    // seleção de todos as fotos
    let elementos2 = document.getElementsByName(el.name);
    for (const elem of elementos2) {
      let check = elem.checked;
      if ((checked && !check) || (!checked && check)) {
        elem.click();
      }

    }
  }

};

export const nomePasta = async (str) => {

  switch (str) {
    case 'em-conferencia':
      return "conferencia"
      break;
    case 'sem-tratamento':
      return "sem_tratamento"
      break;
    case 'pendentes-upload':
      return "upload_pendentes"
      break;
    case 'em-tratamento':
      return "em_tratamento"
      break;
    default:
      return str;
      break;
  }

}

export const handlerDonwloadFotos = async (pastasSelecionadas) => {
  const user = getSessionStorage("user");
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  let fotos = getSessionStorage(`fotos_selecionadas_${marca}`)

  // so pra garantir
  if ((!fotos || fotos.length == 0) && (pastasSelecionadas.length == 0)) { return; }

  const idToast = toast.loading("Aguarde um pouco...")

  fotos = !fotos || fotos.length == 0 ? [] : await fotos.map((e) => { return e.path; });
  const itens = fotos.concat(pastasSelecionadas);

  let pasta = (window.location.pathname).split("/");
  pasta = await nomePasta(pasta.pop());

  let config = {
    method: 'post',
    url: conf.url_fotos() + `/pictures/download/${await id_marca(marca)}?status=${pasta}`,
    headers: { 'Authorization': user.tokenEstudio, 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
    data: JSON.stringify({ "itens": itens })
  };

  axios.request(config).then((response) => {
    const blob = new Blob([response.data], { type: 'application/octet-stream' })
    saveAs(blob, `imagens-farol-${pasta}.zip`)
    toast.update(idToast, { render: "Download concluído com sucesso!", type: "success", isLoading: false, autoClose: 3000 });

  }).catch((error) => {
    toast.update(idToast, { render: "Erro ao realizar download!", type: "error", isLoading: false, autoClose: 5000 });
    console.log(error.message);
  });


};

export const validarNomeFoto = async (arr) => {
  const user = getSessionStorage("user");
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  let retorno = await axios.request({
    method: 'POST',
    url: `${await conf.url_fotos()}/pictures/validation/${await id_marca(marca)
      }`,
    headers: { Authorization: "Bearer " + user.tokenFarol},
    data: JSON.stringify({ "references": arr })
  });

  return retorno.status == 200 ? retorno.data : false
};

export const chooseLogoByBrand = (brand) => {
  if (brand in brandLogos) {
    return brandLogos[brand];
  }
  return null;
};

export const montarArray = (valor, array) => {

  let index = array.indexOf(valor);
  if (index == -1) {
    array.push(valor)
  } else {
    array.splice(index, 1);
  }
  return array
}

export const salvarLoginAceso = () => {
  const usuario = getSessionStorage("user");

  defaultRequest('/log/inserirLog', { id_usuario: usuario.id_usuario, usuario: usuario.email, sistema: 'ESTÚDIO' }).then(function (response) {
  }).catch((error) => {
    console.log(error.message);
    return;
  });
}

export const cardTodosProdutosOfertados = () => {
  document.getElementById("modal_referencias").classList.add('displayNone');
  document.getElementById("cardOffer").classList.remove('displayNone');
}

export const atualizarHtml = (referencias, setReferencias, response) => {
  let novo = []
  if (response.sucesso.length > 0) {
    referencias.forEach((element) => {
      if (!response.sucesso.includes(element.REF)) {
        novo.push(element)
      }
    });
    setReferencias(novo)
  }
};

export const atualizarHtmlTabela = (retorno, texto_total) => {
  if (retorno.length > 0) {
    let elemento_total = document.getElementById(`total_itens`);
    let total = elemento_total.value;
    retorno.forEach((e) => {
      document.getElementById(`elemento_${e} `).remove();
      total--;
    });

    elemento_total.setAttribute('value', total)
    elemento_total.innerHTML = `${texto_total} (${total})`
  }
};

export const toastRetorno = (response) => {

  if (response.error.length > 0) {
    response.error.forEach(e => {
      toast.error(`${e.msg} `, { autoClose: false });
    });
  }
  if (response.sucesso.length > 0) {
    toast.success(response.sucesso.length == 1 ? `Referência aprovada com sucesso!` : `Referências aprovadas com sucesso!`);
  }
};

export const handleClickTab = (e, setValueTab) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(e.currentTarget.name).style.display = "block";
  e.currentTarget.className += " active";


  if (setValueTab) {

    setValueTab(e.currentTarget.name == 'nao_encontrados' || e.currentTarget.name == 'enviados' ? 1 : 2)
  }
};

export const selTodasReferencias = (e, classe, chave, btn) => {

  const check = e ? e.target.checked : false;
  const marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");
  chave = `${chave}_${marca} `

  let elementos = document.getElementsByClassName(classe);
  let ids = [];

  for (const el of elementos) {
    // APENAS PARA SELECIONAR TODOS O CHECKBOX
    if (check && !el.checked) {
      el.click();
    } else if (!check && el.checked) {
      el.click();
    }

    // MONTAR O ARRAY QUE VAI SER SALVO
    if (check) { ids.push(el.value); }
  }

  const element = document.getElementById(btn);

  if (element) {
    if (check) {
      sessionStorage.setItem(chave, JSON.stringify(ids));
      element.classList.add('btn-ativo');
    } else {
      element.classList.remove('btn-ativo');
      sessionStorage.removeItem(chave);
    }
  }
}

export const selRefs = (value, chave, checked, btn) => {

  const marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");
  chave = `${chave}_${marca}`

  let storage = JSON.parse(sessionStorage.getItem(chave)) || [];

  if (checked) {
    storage.push(value);
  } else {
    let index = storage.indexOf(value);
    storage.splice(index, 1);
  }


  if (storage.length > 0) {
    sessionStorage.setItem(chave, JSON.stringify(storage))
    document.getElementsByClassName(btn)[0].classList.add('btn-ativo');
  } else {
    sessionStorage.removeItem(chave)
    document.getElementsByClassName(btn)[0].classList.remove('btn-ativo');
  }

}

export const filiaisEstudio = (marca) => {
  switch (marca) {
    case 'FABULA':
      return ["MARKETING FABULA"];
      break;
    case 'FARM':
      return ["ESTUDIO FARM FB", "FOTO ECOMMERCE FARM"];
      break;
    case 'MARIA FILO':
      return ["EST MOST MKT"];
      break;
    default:
      return ["STUDIO SOMA AAF & FFF"];
      break;
  }
}

export const habilitarBotoes = (tipo) => {

  let [btnEmail] = document.getElementsByClassName('btn-enviarEmail');
  let [btnData] = document.getElementsByClassName('btn-dataPricker');
  let [btnComentario] = document.getElementsByClassName('btn-comentario');

  if (window.innerWidth > 959) {
    btnComentario.classList.remove("icones-cinza");

    // aba principal = 1
    if (tipo == 1) {
      btnEmail.classList.remove("icones-cinza");
      btnData.classList.remove("icones-cinza");
    }
  } else {
    btnComentario.classList.remove("icones-cinza");
    btnComentario.classList.add("icones-black");
    if (tipo == 1) {
      btnEmail.classList.remove("icones-cinza");
      btnEmail.classList.add("icones-black");
      btnData.classList.remove("icones-cinza");
      btnData.classList.add("icones-black");
    } else {
      btnEmail.classList.remove("icones-black");
      btnData.classList.remove("icones-black");
      btnEmail.classList.add("icones-cinza");
      btnData.classList.add("icones-cinza");
    }
  }
}

export const desabilitarBotoes = () => {
  let [btnEmail] = document.getElementsByClassName('btn-enviarEmail');
  let [btnData] = document.getElementsByClassName('btn-dataPricker');
  let [btnComentario] = document.getElementsByClassName('btn-comentario');
  btnEmail.classList.add("icones-cinza");
  btnData.classList.add("icones-cinza");
  btnComentario.classList.add("icones-cinza");
}

export const formataBotoes = (tipoClasse) => {
  let [tabMobile] = document.getElementsByClassName('tab-mobile');
  let [boxTabs] = document.getElementsByClassName('box-tabs');
  let [dataPickerEmail] = document.getElementsByClassName('data-picker-email');
  let [flexTab] = document.getElementsByClassName('flex-tab');


  if (tipoClasse == "adicionar-estilo") {
    tabMobile.classList.add('tab-mobile-formata');
    boxTabs.classList.add('box-tabs-formata');
    boxTabs.classList.remove('icones-esconder');
    dataPickerEmail.classList.add('data-picker-email-formata');
    flexTab.classList.add("flex-tab-mobile");
  }
  else {
    tabMobile.classList.remove('tab-mobile-formata');
    boxTabs.classList.remove('box-tabs-formata');
    dataPickerEmail.classList.remove('data-picker-email-formata');
    boxTabs.classList.add('icones-esconder');
  }
}

export const ordenarDados = (rows, boleano, campo) => {

  return rows.sort(function (a, b) {
    return boleano ? a[campo] - b[campo] : b[campo] - a[campo];
  });
}

export const extrairColunas = async (dados, setStatus, setFiliais) => {
  var status_imagens = [];
  var valid_filiais = [];

  for (const el of dados) {
    if (!status_imagens.includes(!el.FOTO ? "SEM STATUS" : el.FOTO)) {
      status_imagens.push(!el.FOTO ? "SEM STATUS" : el.FOTO)
    }
    if (!valid_filiais.includes(el.FILIAL)) {
      valid_filiais.push(el.FILIAL)
    }
  }
  setStatus(status_imagens.sort())
  setFiliais(valid_filiais.sort())
}

export const filtrarDados = async (dados, valid, campo) => {

  let dadosFiltrados = await dados.filter(item => {

    if (valid.length == 0) {
      return item;
    } else if (valid.includes(String(item[campo]))) {
      return item;
    }
  })

  if (dadosFiltrados.length > 0) {
    return dadosFiltrados;
  } else {
    return dados;
  }
}

export const formatPrice = (valor) => {
  valor = valor ? valor : 0;
  return (valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: getSessionStorage("user").marcaSelecionada == 'FARM GLOBAL' ? 'USD' : 'BRL'
  });
}

export const converter_data = (data) => {
  if (data) {
    return data.substr(0, 10).split("-").reverse().join("/");
  } else {
    return "";
  }
};

export const nome_pacote = (pacote, tipo) => {

  if (pacote) {

    if (((pacote.indexOf("-") != -1) || (pacote.indexOf("/") != -1)) && pacote.length == 10) {
      if (tipo == 'pt-BR') {
        return pacote.substr(0, 10).split("-").reverse().join("/");
      } else {
        return pacote.substr(0, 10).split("/").reverse().join("-");
      }
    } else {
      return pacote
    }
  } else {
    return "";
  }
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const format_data_full = (date) => {
  date = new Date(date)
  return (
    [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear()
    ].join('/') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

export const format_data = (data) => {
  if (data) {
    return data.split('-').reverse().join('/')
    // data = new Date(data)
    // return data.toLocaleDateString('pt-BR')
  } else {
    return "";
  }
};

export const marcasUtilizadas = async (dados) => {
  let marcasPermitidas = [];
  const marcasAtuais = [
    "ANIMALE",
    "FOXTON",
    "FABULA",
    "FARM",
    "OFF",
    "BYNV",
    "MARIA FILO",
    "CRIS BARROS",
    "FARM GLOBAL",
    "HERING",
  ];
  dados.forEach((element) => {
    if (marcasAtuais.includes(element)) {
      marcasPermitidas.push(element);
    }
  });

  return marcasPermitidas = marcasPermitidas.sort();
};

export const objToCSV = async (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = `${Object.keys(array[0]).map((value) => `"${value}"`).join(",")} ` + "\r\n";
  let novo = array.reduce((str, next) => {
    str += `${Object.values(next).map((value) => `"${value}"`).join(",")} ` + "\r\n";
    return str;
  }, str);

  let data = new Date();
  let name = `Atributos_vtex_${getSessionStorage("user").marcaSelecionada}_${data.getDate()} -${(data.getMonth() + 1)} -${data.getFullYear()} `;

  let hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(novo);
  hiddenElement.target = "_blank";
  hiddenElement.download = name + ".csv";
  hiddenElement.click();
};

export const getRequest = async (rota, setLoading = null) => {

  if (setLoading) { setLoading(true); }
  const user = getSessionStorage("user");

  let reqOptions = {
    url: conf.url() + rota,
    method: "GET",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.tokenFarol,
    },
  };
  return await axios.request(reqOptions).then(function (response) {
    if (setLoading) { setLoading(false); }
    return response.data;
  });
};

export const defaultRequest = async (rota, params = null, setLoading = null) => {

  let valoresSelect = sessionStorage.getItem('params');
  if (valoresSelect) {
    valoresSelect = JSON.parse(valoresSelect);
    params.filtro_colecao = valoresSelect.filtro_colecao ? valoresSelect.filtro_colecao : false;
    params.filtro_lancados = valoresSelect.filtro_lancados ? valoresSelect.filtro_lancados : false;
    params.filtro_marca = valoresSelect.filtro_marca ? valoresSelect.filtro_marca : false;
    params.filtro_canal = valoresSelect.filtro_canal ? valoresSelect.filtro_canal : false;
    params.filtro_pacotes = valoresSelect.filtro_pacotes ? valoresSelect.filtro_pacotes : false;
    sessionStorage.setItem('params', JSON.stringify(params));
  }

  if (setLoading) { setLoading(true); }

  const user = getSessionStorage("user");

  if (user)
    params.marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");;
  let reqOptions = {
    url: conf.url() + rota,
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.tokenFarol,
    },
    data: params,
  };
  return await axios.request(reqOptions).then(function (response) {
    if (setLoading) { setLoading(false); }
    return response.data;
  });
};

export const montarTelaPrincialGestao = async (response, setDados, setLoading) => {
  try {

    let dados_geral = response.dados_geral;

    let nao_ofertados_receita = dados_geral.skus_ecom_nao_vendidos_receita + dados_geral.total_operacao_receita;
    let nao_ofertados = dados_geral.skus_ecom_nao_vendidos + dados_geral.total_operacao_skus;

    let porcentagem = nao_ofertados_receita == 0 ? 0 : ((nao_ofertados_receita * 100) / (nao_ofertados_receita + dados_geral.skus_vendidos_receita)).toFixed(2);
    let ofertadosPorcentagem = porcentagem == 0 ? 0 : (100 - porcentagem).toFixed(2);

    let ret = {};
    ret.colecoes = response.colecoes;
    ret.pacotes = response.pacotes;
    ret.atualizado = response.atualizado;
    ret.qtdSkusTotal = nao_ofertados + dados_geral.skus_vendidos;
    ret.qtdRefsTotal = dados_geral.skus_ecom_nao_vendidos_refs + dados_geral.total_operacao_refs + dados_geral.skus_vendidos_refs;
    ret.naoOfertadosQtdRefs = dados_geral.skus_ecom_nao_vendidos_refs + dados_geral.total_operacao_refs;

    ret.receitaTotal = nao_ofertados_receita + dados_geral.skus_vendidos_receita;
    ret.todosProdutosOfertados = ofertadosPorcentagem < 100 ? false : true;
    ret.ofertadosPorcentagem = ofertadosPorcentagem;
    ret.naoOfertadosPorcentagem = porcentagem;
    ret.ofertados = dados_geral.skus_vendidos;
    ret.ofertadosRefs = dados_geral.skus_vendidos_refs;
    ret.ofertadosReceita = dados_geral.skus_vendidos_receita;
    ret.naoOfertados = nao_ofertados;
    ret.naoOfertadosReceita = nao_ofertados_receita;
    ret.top_6 = response.dados_card.splice(0, 6);

    let dados_marketplace = response.dados_marketplace;
    ret.naoOfertadosMarketplace = dados_marketplace.pendentes_marketplace + dados_marketplace.nao_encontrados_marketplace;
    ret.naoOfertadosMarketplaceReceita = dados_marketplace.pendentes_marketplace_receita + dados_marketplace.nao_encontrados_marketplace_receita;
    ret.ofertadosMarketplace = dados_marketplace.ofertados_marketplace;
    
    ret.naoOfertadosMarketplaceRefs = dados_marketplace.pendentes_marketplace_refs + dados_marketplace.nao_encontrados_marketplace_refs;
    ret.ofertadosMarketplaceRefs = dados_marketplace.ofertados_marketplace_refs;

    ret.ofertadosMarketplaceReceita = dados_marketplace.ofertados_marketplace_receita;
    ret.totalMarketplace = dados_marketplace.pendentes_marketplace + dados_marketplace.nao_encontrados_marketplace + dados_marketplace.ofertados_marketplace;
    ret.totalMarketplaceReceita = dados_marketplace.pendentes_marketplace_receita + dados_marketplace.nao_encontrados_marketplace_receita + dados_marketplace.ofertados_marketplace_receita;

    setDados(ret);
    setLoading(false)


  } catch (error) {
    console.log(error.message)
  }
};

export const montarTelaNaoOfertados = async (response, setDados, setLoading) => {


  try {
    if (!response.dados == 0)
      return;

    let dados_card = response.dados_card;
    let dadosOrdenadosCatalogo = await dados_card.filter(item => { return item.badge == "catalogo"; })
    let dadosOrdenadosOperacao = await dados_card.filter(item => { return item.badge == "operacao"; })

    let dados_geral = response.dados_geral;
    let total = dados_geral.skus_ecom_nao_vendidos_receita + dados_geral.total_operacao_receita;
    let porcentagem = dados_geral.skus_ecom_nao_vendidos_receita == 0 ? 0 : ((dados_geral.skus_ecom_nao_vendidos_receita * 100) / total).toFixed(2);
    let porcentagem2 = porcentagem == 0 ? 0 : (100 - porcentagem).toFixed(2);
  

    
    let ret = {};
    ret.dadosOrdenados = dados_card;
    ret.dadosOrdenadosCatalogo = dadosOrdenadosCatalogo;
    ret.dadosOrdenadosOperacao = dadosOrdenadosOperacao;
    ret.catalogoReceita = dados_geral.skus_ecom_nao_vendidos_receita;
    ret.catalogo = dados_geral.skus_ecom_nao_vendidos;
    ret.catalogoRefs = dados_geral.skus_ecom_nao_vendidos_refs;


    ret.operacaoReceita = dados_geral.total_operacao_receita;
    ret.operacao = dados_geral.total_operacao_skus;
    ret.operacaoRefs = dados_geral.total_operacao_refs;
    ret.catalogoPorcentagem = porcentagem;
    ret.operacaoPorcentagem = porcentagem2;
    
    ret.qtdSkusTotal = dados_geral.skus_ecom_nao_vendidos + dados_geral.total_operacao_skus;
    ret.qtdRefsTotal = dados_geral.skus_ecom_nao_vendidos_refs + dados_geral.total_operacao_refs;
    
    ret.receitaTotal = total;
    ret.colecoes = response.colecoes;
    ret.atualizado = response.atualizado;
    ret.pacotes = response.pacotes;
    setDados(ret)
    setLoading(false)

  } catch (error) {
    console.log(error.message)

  }
};

export const transformTextModal = () => {
  return {
    catalogo: "Critérios relaciondos à oferta através dos atributos da VTEX",
    apenas_skus_nao_encontrados_ecom: "Ocorre quando referência_cor não foi encontrada na VTEX.",
    apenas_estoque_ecom: "Ocorre quando referência_cor está sem estoque na VTEX (há estoque no linx, mas ele não é refletido na VTEX). Alguns exemplos são ruptura de estoque (política zera estoque) e quando não há catálogo cadastrado.",
    apenas_estoque_imagens_ecom: "Ocorre quando referência cor está sem estoque e sem imagem na VTEX",
    apenas_estoque_ativo_ecom: "Ocorre quando referência cor está sem estoque e não está ativo na VTEX",
    apenas_estoque_visivel_ecom: "Ocorre quando referência cor está sem estoque e não está visível na VTEX",
    apenas_estoque_categoria_ecom: "Ocorre quando referência cor está sem estoque e não categorizada na VTEX",
    apenas_estoque_ativo_visivel_ecom: "Ocorre quando referência cor está sem estoque, não está ativo e não está visível na VTEX",
    apenas_estoque_imagens_ativo_ecom: "Ocorre quando referência cor está sem estoque, sem imagem e não ativo na VTEX",
    apenas_estoque_imagens_visivel_ecom: "Ocorre quando referência cor está sem estoque, sem imagem e não visível na VTEX",
    apenas_estoque_imagens_categoria_ecom: "Ocorre quando referência cor está sem estoque, sem imagem e não categorizada na VTEX",
    apenas_estoque_imagens_ativo_visivel_ecom: "Ocorre quando referência cor está sem estoque, sem imagem, não ativo e não visível na VTEX",
    apenas_estoque_imagens_ativo_categoria_ecom: "Ocorre quando referência cor está sem estoque, sem imagem, não ativo e não categorizada na VTEX",
    apenas_estoque_imagens_visivel_categoria_ecom: "Ocorre quando referência cor está sem estoque, sem imagem, não visível e não categorizada na VTEX",
    apenas_imagens_ecom: "Ocorre quando referência cor está sem imagem na VTEX",
    apenas_imagens_ativo_ecom: "Ocorre quando referência cor está sem imagem e não ativo na VTEX",
    apenas_imagens_visivel_ecom: "Ocorre quando referência cor está sem imagem e não visível na VTEX",
    apenas_imagens_categoria_ecom: "Ocorre quando referência cor está sem imagem e não categorizada na VTEX",
    apenas_imagens_ativo_visivel_ecom: "Ocorre quando referência cor está sem imagem, não ativo e não visível na VTEX",
    apenas_imagens_ativo_categoria_ecom: "Ocorre quando referência cor está sem imagem, não ativo e não categorizada na VTEX",
    apenas_imagens_visivel_categoria_ecom: "Ocorre quando referência cor está sem imagem, não visível e não categorizada na VTEX",
    apenas_imagens_ativo_visivel_categoria_ecom: "Ocorre quando referência cor está sem imagem, não ativo, não visível e não categorizada na VTEX",
    apenas_visivel_ecom: "Ocorre quando referência cor não está visível na VTEX",
    apenas_ativo_ecom: "Ocorre quando referência cor não está ativo na VTEX",
    apenas_visivel_ativo_ecom: "Ocorre quando referência cor não está visível e não ativo na VTEX",
    operacao: "Fatores relacionados à operação Omni",
    skus_nao_vendidos_por_regra_deflator_operacao: "O deflator de marca indiponibiliza peças abaixo de X unidades na rede de lojas da marca",
    skus_indisponivel_deflator_marca_operacao: "O deflator de seller é responsável por gerenciar uma margem de segurança do estoque, indisponibilizando, quando aplicado, X peças de um SKU do seller.",
    skus_indisponivel_regra_integracao_operacao: "Produtos não ofertados devido à sua exclusão na regra de integração, podendo ser por coleção, preço mínimo ou exclusão da referência",
    skus_lojas_desligadas_operacao: "Ocorre quando há algum seller omni desligado ",
    skus_lojas_desligadas_teto_operacao: "Ocorre quando um seller omni atinge o teto, então ele é desligado até estar novamente em condições (quantidade de pedidos abaixo do limite máximo) para operação",
    skus_nao_estao_em_lojas_abrangentes_operacao: "SKUs que não estão sendo ofertados no site pois estão apenas em sellers não abrangentes, ou seja, sellers que não integram o catálogo do site",
    skus_lojas_nao_conectado_digital_operacao: "SKU está em uma filial do grupo que não está configurada para venda no digital ",
    sku_indisponivel_embalado_operacao: "SKUs embalados ficam indisponíveis para a venda online ",
    sku_indisponivel_transito_operacao: "SKUs indisponíveis para a venda online pois estão sendo transferidos de uma filial para outra",
    sku_indisponivel_reserva_operacao: "SKUs indisponíveis para a venda online pois estão reservados no seller",
    somente_atacado_operacao: "SKUs indisponíveis para a venda online pois estão reservados para apenas atacado",
    nao_encontrados_marketplace: "SKUs indisponíveis para a venda online ",
    pendentes_marketplace: "SKUs pendentes para aprovação ",
    ofertados_marketplace: "SKUs ofertados no marketplace ",
    nao_ofertados_marketplace: "SKUs não ofertados no marketplace ",
    cancelados_operacao: "SKUs cancelados",
  }
};

export const transformTitleModal = () => {
  return {
    catalogo: "Catálogo",
    apenas_skus_nao_encontrados_ecom: "Não encontrados ecommerce",
    apenas_estoque_ecom: "Sem estoque",
    apenas_estoque_imagens_ecom: "Sem estoque e sem imagem",
    apenas_estoque_ativo_ecom: "Sem estoque e não ativo",
    apenas_estoque_visivel_ecom: "Sem estoque e não visível",
    apenas_estoque_categoria_ecom: "Sem estoque e sem categoria",
    apenas_estoque_ativo_visivel_ecom: "Sem estoque, não ativo e não visível",
    apenas_estoque_imagens_ativo_ecom: "Sem estoque, sem imagem e não ativo",
    apenas_estoque_imagens_visivel_ecom: "Sem estoque, sem imagem e não visível",
    apenas_estoque_imagens_categoria_ecom: "Sem estoque, sem imagem e sem categoria",
    apenas_estoque_imagens_ativo_visivel_ecom: "Sem estoque, sem imagem, não ativo e não visível",
    apenas_estoque_imagens_ativo_categoria_ecom: "Sem estoque, sem imagem, não ativo e sem categoria",
    apenas_estoque_imagens_visivel_categoria_ecom: "Sem estoque, sem imagem, não visível e sem categoria",
    apenas_imagens_ecom: "Sem imagem",
    apenas_imagens_ativo_ecom: "Sem imagem e não ativo",
    apenas_imagens_visivel_ecom: "Sem imagem e não visível",
    apenas_imagens_categoria_ecom: "Sem imagem e sem categoria",
    apenas_imagens_ativo_visivel_ecom: "Sem imagem, não ativo e não visível",
    apenas_imagens_ativo_categoria_ecom: "Sem imagem, não ativo e sem categoria",
    apenas_imagens_visivel_categoria_ecom: "Sem imagem, não visível e sem categoria",
    apenas_imagens_ativo_visivel_categoria_ecom: "Sem imagem, não ativo, não visível e sem categoria",
    apenas_visivel_ecom: "Não visível",
    apenas_ativo_ecom: "Não ativo",
    apenas_visivel_ativo_ecom: "Não visível e não ativo",
    operacao: "Operação",
    skus_nao_vendidos_por_regra_deflator_operacao: "Indisponibilidade devido ao deflator de seller",
    skus_indisponivel_deflator_marca_operacao: "Indisponibilidade devido ao deflator de marcas",
    skus_indisponivel_regra_integracao_operacao: "Indisponibilidade devido a regra de integração de seller",
    skus_lojas_desligadas_operacao: "Omni desligado",
    skus_lojas_desligadas_teto_operacao: "Omni desligado teto atingido",
    skus_nao_estao_em_lojas_abrangentes_operacao: "Estoque não ofertado por sellers não abrangentes",
    skus_lojas_nao_conectado_digital_operacao: "Estoque não conectado ao digital",
    sku_indisponivel_embalado_operacao: "Estoque  conectado ao digital indisponível (embaladas)",
    sku_indisponivel_transito_operacao: "Estoque  conectado ao digital indisponível (em transito)",
    sku_indisponivel_reserva_operacao: "Estoque conectado ao digital indisponível (reservado)",
    somente_atacado_operacao: "Estoque disponível apenas para atacado",
    nao_encontrados_marketplace: "Não encontrados",
    pendentes_marketplace: "Pendentes",
    ofertados_marketplace: "Ofertados marketplace",
    nao_ofertados_marketplace: "Não ofertados marketplace",
    cancelados_operacao: "Cancelados",
  }
};

export const sendGTAGEventByID = (id, email) => {
  if (id === "skus_indisponivel_deflator_marca_operacao") {
    SendGTAGEvent('card_1_receita', 'nao_ofertados', 'click', email);
  }

  if (id === "apenas_visivel_ecom") {
    SendGTAGEvent('card_2_receita', 'nao_ofertados', 'click', email);
  }

  if (id === "apenas_imagens_visivel_ecom") {
    SendGTAGEvent('card_3_receita', 'nao_ofertados', 'click', email);
  }
}

export const changeParams = async (e, params, setParams, tipo, selecionados) => {

  if (tipo) {
    params[tipo] = selecionados[0] == "Todas" || selecionados[0] == "Todos" ? false : selecionados
    setParams(params);
    sessionStorage.setItem('params', JSON.stringify(params));
  } else {
    e.preventDefault();
    params[e.target.name.toLowerCase()] = e.target.value;
    setParams(params);
    sessionStorage.setItem('params', JSON.stringify(params));
  }
}

export const changeSelectScrol = async (setDados, setLoading, route, params, tela) => {
  defaultRequest(route, params).then(function (response) {
    switch (tela) {
      case 'principal':
        montarTelaPrincialGestao(response, setDados, setLoading);
        break;
      case 'naoOfertados':
        montarTelaNaoOfertados(response, setDados, setLoading);
        break;
      default:
        setDados(response)
        setLoading(false)
        break;
    }
  })
}

export const buscaModal = async (termo, produtos) => {
  return produtos.filter(item => { return item.REF == termo; })
}

export const exportarCsv = async (objArray, name) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str =
    `${Object.keys(array[0]).map((value) => `"${value}"`).join(",")} ` + "\r\n";

  let novo = array.reduce((str, next) => {
    str += `${Object.values(next).map((value) => `"${value}"`).join(",")} ` + "\r\n";
    return str;
  }, str);

  let hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(novo);
  hiddenElement.target = "_blank";
  hiddenElement.download = name + ".csv";
  hiddenElement.click();
};

export const colorirMenu = async () => {
  let url = window.location.pathname;
  if (url.indexOf("estudio") != -1) {
    document.querySelectorAll(".estudio").forEach(element => { element.style.backgroundColor = "#dcc6b7" });
    document.querySelectorAll(".gestao, .log, .performance, .fotos").forEach(element => { element.style.backgroundColor = "#inherit" });
  } else if (url.indexOf("gestao") != -1) {
    document.querySelectorAll(".gestao").forEach(element => { element.style.backgroundColor = "#dcc6b7" });
    document.querySelectorAll(".estudio, .log, .performance, .fotos").forEach(element => { element.style.backgroundColor = "#inherit" });
  } else if (url.indexOf("log") != -1) {
    document.querySelectorAll(".log").forEach(element => { element.style.backgroundColor = "#dcc6b7" });
    document.querySelectorAll(".gestao, .estudio, .performance, .fotos").forEach(element => { element.style.backgroundColor = "#inherit" });
  } else if (url.indexOf("performance") != -1) {
    document.querySelectorAll(".performance").forEach(element => { element.style.backgroundColor = "#dcc6b7" });
    document.querySelectorAll(".gestao, .log, .estudio, .fotos").forEach(element => { element.style.backgroundColor = "#inherit" });
  } else if (url.indexOf("fotos") != -1) {
    document.querySelectorAll(".fotos").forEach(element => { element.style.backgroundColor = "#dcc6b7" });
    document.querySelectorAll(".gestao, .log, .estudio, .performance").forEach(element => { element.style.backgroundColor = "#inherit" });
  }
};

export default {};
