import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import "./ModalMessage.css"
import { getSessionStorage } from "../../../utils/sessionStorage";



export default function ModalMessage({ salvarComentario, tab }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    let storage = null;
    if (tab == 1) {
      storage = getSessionStorage(`refSelecionadasPacote_${getSessionStorage("user").marcaSelecionada}`);
    } else {
      storage = getSessionStorage(`refSelecionadasPacoteUpload_${getSessionStorage("user").marcaSelecionada}`);
    }
    if (storage) {
      setOpen(true);
    }
  }





  const handleClose = () => setOpen(false);

  const salvar = () => {
    salvarComentario();
    setOpen(false);
  };

  return (
    <div >
      <MessageOutlinedIcon onClick={handleOpen} />
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Box className="modalFormat">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Adicionar comentário</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel value="Ref_Cor com defeito" control={<Radio />} label="Ref_Cor com defeito" />
              <FormControlLabel value="Ref_Cor sem liberação" control={<Radio />} label="Ref_Cor sem liberação" />
              <FormControlLabel value="Ref_Cor não distribuída" control={<Radio />} label="Ref_Cor não distribuída" />
              <FormControlLabel value="Ref_Cor migrada para outra coleção" control={<Radio />} label="Ref_Cor migrada para outra coleção" />
              <FormControlLabel value="Refotografar - divergência foto e peça final (cor)" control={<Radio />} label="Refotografar - divergência foto e peça final (cor)" />
              <FormControlLabel value="Refotografar - divergência foto e peça final (detalhes)" control={<Radio />} label="Refotografar - divergência foto e peça final (detalhes)" />
              <FormControlLabel value="Refotografar - divergência foto e peça final (material)" control={<Radio />} label="Refotografar - divergência foto e peça final (material)" />
              <FormControlLabel value="Refotografar - fundo divergente" control={<Radio />} label="Refotografar - fundo divergente" />
              <FormControlLabel value="Brinde" control={<Radio />} label="Brinde" />
              <FormControlLabel value="Estoque baixo" control={<Radio />} label="Estoque baixo" />
              <FormControlLabel value="Alta devolução" control={<Radio />} label="Alta devolução" />
              <FormControlLabel value="Pendencia de lookbook" control={<Radio />} label="Pendencia de lookbook" />
              <FormControlLabel value="Sem foto ecommerce" control={<Radio />} label="Sem foto ecommerce" />
              <FormControlLabel value="Peça exclusivo atacado" control={<Radio />} label="Peça exclusivo atacado" />
              <FormControlLabel value="Ref_cor cancelada" control={<Radio />} label="Ref_cor cancelada" />
            </RadioGroup>
            <div className="adicionar">
              <Button variant="contained" onClick={salvar} className='adicionar-botao'>ADICIONAR</Button>
            </div>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}