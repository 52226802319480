import React, { useState, useRef, useEffect } from 'react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import "./Dropdown.css"

const Dropdown = ({ filtrar, titulo, itens, filtro, removerFiltros }) => {

  const largura = titulo == "Local" ? "220px" : "170px";
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // return () => { document.removeEventListener('mousedown', handleClickOutside); };
  }, []);

  return (
    <div className="dropdown">
      <div className="dropdown-header">

        <label className="label-icones" onClick={() => setIsOpen(!isOpen)}>
          <span>{titulo}</span>
          <FilterAltOutlinedIcon className="icone-filtro" />
        </label>
        <div className="icone-x-qtd">{Array.from(filtro).length > 0 ? `(${Array.from(filtro).length})` : ""}</div>
        <div className="icone-x-qtd" onClick={() => removerFiltros(titulo)} >{Array.from(filtro).length > 0 ? "X" : ""}</div>
      </div>

      {isOpen && (
        <div ref={dropdownRef} className="dropdown-content" style={{ "width": largura }}>
          <a onClick={filtrar} value={'default'}>{titulo}</a>
          {itens.map((item) => {
            return <label className="custom-checkbox item-dropdown-content">
              <input type="checkbox" defaultChecked={filtro.includes(String(item == "SEM STATUS" ? false : item))} value={item == "SEM STATUS" ? false : item} id={item} tipo={titulo} onClick={filtrar} />
              <span className="checkmark"></span>
              <div className='nome-status '>{item}</div>
            </label>
          })}

        </div>
      )}
    </div>
  );
};

export default Dropdown;


