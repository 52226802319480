import React from "react";
import { useState, useEffect } from "react";
import MenuFarolDeskMobile from "../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid } from "@mui/material";
import { defaultRequest, exportarCsv, handleClickTab, formatPrice } from "../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import "./acessos.css";
import Row from "./RowScraping";
import HeaderDesk from "../../components/Gestao/HeaderDesk/HeaderDesk";
// import ScrollSelect from "../../components/Gestao/ScrollSelect/ScrollSelect";
import { Skeleton } from "@mui/material";

export default function Scraping() {
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosIndisponiveis, setDadosIndisponivieis] = useState([]);
  const [dadosNaoEncontrados, setDadosNaoEncontrados] = useState([]);
  // const [colecoes, setColecoes] = useState([]);
  // const [params, setParams] = useState({ filtro_colecao: false, filtro_marca: false });
  // const [loading, setLoading] = useState();
  const [valueTab, setValueTab] = useState(1);

  const [receitaReceitaIndisponiveis, setReceitaReceitaIndisponiveis] = useState(0);
  const [receitaNaoEncontrados, setReceitaNaoEncontrados] = useState(0);


  useEffect(async () => {

    defaultRequest("/scraping", {}).then((ret) => {
      // console.log(ret)
      setDadosOriginais(ret);
      setDadosIndisponivieis(ret.nao_disponiveis)
      setDadosNaoEncontrados(ret.nao_encontrados)

      setReceitaReceitaIndisponiveis(ret.nao_disponiveis.reduce((prev, curr) => prev + parseFloat(curr.receita), 0))
      setReceitaNaoEncontrados(ret.nao_encontrados.reduce((prev, curr) => prev + parseFloat(curr.receita), 0))

    })

  }, []);

  const exportar = async (tipo) => {
    const dadosExportar = tipo == "nao_encontrados" ? dadosNaoEncontrados : dadosIndisponiveis;
    const nome = tipo == "nao_encontrados" ? "SCRAPING_NÃO_ENCONTRADOS" : "SCRAPING_NÃO_DISPONÍVEIS";
    exportarCsv(dadosExportar, nome);
  };

  return (
    <>
      <MenuFarolDeskMobile
        title={
          <HeaderDesk
            atualizado={dadosOriginais.atualizado}
            setBusca={false}
            setLoading={false}
            tipoTela={"log resumo diário"}
            title={"SCRAPING"}
            enderecoVoltar={"/gestao"}
          />
        }
        body={
          <div className="card-total">
            <div className="divider-pacote" />

            {/* <div className="box-geral resumo-diario"> */}
            {/* <ScrollSelect
                setLoading={setLoading}
                colecoes={colecoes}
                params={params}
                setParams={setParams}
                dadosOriginais={dadosOriginais}
                setDadosTable={setDadosTable}
                tela={"log resumo diário"}
              /> */}
            {/* <button className="btn-exportar-log" onClick={() => exportar(1)}> Exportar </button> */}
            {/* </div> */}

            <Grid container className="box-tab-pai box-tab-pai-scraping" >

              {dadosOriginais.length == 0 ? (<Skeleton className="skeleton-format skeleton-nao-ofertados" variant="rectangular" />) : (<>
                <div className="total-scraping">
                  Total de produtos: ({dadosOriginais.qtd_total})
                </div>

                <div className="tab tab-scraping" id="id_scroll_btn_aprovar">
                  <div className="box-tab-btn">
                    <button name="nao_encontrados" className="tablinks active" onClick={(e) => handleClickTab(e, setValueTab)} >
                      Não Encontrados ({dadosOriginais.nao_encontrados.length})
                    </button>
                    <button id="total_itens" name="indisponiveis" className="tablinks" onClick={(e) => handleClickTab(e, setValueTab)} >
                      Nao disponíveis ({dadosOriginais.nao_disponiveis.length})
                    </button>

                  </div>
                </div>

                {/* <div className="tab-box"> */}
                <div id="nao_encontrados" className="tabcontent" style={{ display: "flex" }} >

                  <Grid container spacing={1}>

                    <div className="total-exportar">
                      <spam className="input-total-receita">Receita não encontrados: {formatPrice(receitaNaoEncontrados)}</spam>
                      <button className="btn-exportar-log btn-exportar-scraping" onClick={() => exportar('nao_encontrados')}> Exportar </button>
                    </div>

                    <table className="centralizar-tabela">
                      <thead>
                        <tr className="header-tabela">
                          <th className="th">Referência</th>
                          <th className="th">Coleção</th>
                          <th className="th">Receita</th>
                          <th className="th">Url</th>
                        </tr>
                      </thead>

                      <tbody>
                        {dadosNaoEncontrados.length > 0 && dadosNaoEncontrados.map((iten) => { return <Row row={iten} />; })}
                      </tbody>
                    </table>

                  </Grid>
                </div>

                <div id="indisponiveis" className="tabcontent " style={{ display: "none" }} >
                  <Grid container spacing={1} >

                    <div className="total-exportar">
                      <spam className="input-total-receita">Receita indisponíveis: {formatPrice(receitaReceitaIndisponiveis)}</spam>
                      <button className="btn-exportar-log btn-exportar-scraping" onClick={() => exportar('indisponiveis')}> Exportar </button>
                    </div>

                    {/* <div className="total-exportar">
                        <spam className="input-total-receita">Receita indisponível: {receitaReceitaIndisponiveis}</spam>
                        <button className="btn-exportar-log btn-exportar-scraping" onClick={() => exportar("indisponiveis")}> Exportar </button>
                      </div> */}

                    <table className="centralizar-tabela">
                      <thead>
                        <tr className="header-tabela">
                          <th className="th">Referência</th>
                          <th className="th">Coleção</th>
                          <th className="th">Receita</th>
                          <th className="th">Url</th>
                        </tr>
                      </thead>

                      <tbody>
                        {dadosIndisponiveis.length > 0 && dadosIndisponiveis.map((iten) => { return <Row row={iten} />; })}
                      </tbody>
                    </table>


                  </Grid>
                </div>

                {/* </div> */}
              </>)}
            </Grid>



            {/* <Grid container className="">
                  <table>
                    <thead>
                      <tr className="header-tabela">
                        <th className="th">Coleção</th>
                        <th className="th">Data</th>
                        <th className="th">Receita</th>
                        <th className="th">Receita Ofertados</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dadosTable.length > 0
                        ? dadosTable.map((iten) => {
                          return <Row row={iten} />;
                        })
                        : ""}
                    </tbody>
                  </table>
                </Grid> */}

          </div>
        }
      />
    </>
  );
}
