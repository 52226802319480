import * as React from "react";
import { useState, useEffect } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { filtrarDados, ordenarDados } from "../../../utils/functions";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import Row from "./RowUpload";
import "./Tabela.css";

export default function Tabela({ rows }) {
  const [dados, setDados] = useState(rows);
  const [dadosOriginal, setDadosOriginal] = useState(rows);
  const [valorReceitaOrder, setValorReceitaOrder] = useState(null);
  // const [isOpenCat, setIsOpenCat] = useState('');

  useEffect(() => {
    // setDados(rows);
    // setDadosOriginal(rows);
  }, [rows]);

  const filtrar = async (e) => {
    const element = e.target;
    const valor = element.getAttribute('value');
    const coluna = element.getAttribute('coluna')

    if (valor == "default") {
      setDados(dadosOriginal);
    } else {
      setDadosOriginal(rows);
      setDados(false);
      let refFiltradas = await filtrarDados(rows, coluna, valor);
      setDados(refFiltradas);
    }
    // setIsOpenCat(false);
  };

  const sortValorReceita = () => {
    dados.sort(function (a, b) { return valorReceitaOrder == 'desc' ? a.PRECO_ECOMMERCE - b.PRECO_ECOMMERCE : b.PRECO_ECOMMERCE - a.PRECO_ECOMMERCE; })
    setDados(dados)
    setValorReceitaOrder(valorReceitaOrder == 'desc' ? 'asc' : 'desc');
  };

  return (
    <div className="tabela-responsiva">
      <table>
        <thead>
          <tr className="header-tabela">
            <th>Referência</th>
            <th>
              <div className='valor-estoque'> Valor Estoque
                <button className='sort' onClick={sortValorReceita}>
                  {valorReceitaOrder === "asc" ? (<SortRoundedIcon />) : valorReceitaOrder === "desc" ? (<SortRoundedIcon />) : (<SortRoundedIcon />)}
                </button>
              </div>
            </th>
            <th>Coleção</th>
            {/* <th> <Dropdown filtrar={filtrar} titulo={'Categoria'}
              itens={[[true, "OK"], [false, "NÃO"]]}
              coluna={'category'}>
              Categoria </Dropdown> </th> */}
            <th>Categoria</th>
            {/* <th> <Dropdown filtrar={filtrar} titulo={'Imagem'}
              itens={[[true, "OK"], [false, "NÃO"]]}
              coluna={'images_ecom'}>
              Imagem </Dropdown> </th> */}
            <th>Imagem</th>

            {/* <th> <Dropdown filtrar={filtrar} titulo={'Estoque'}
              itens={[[1, "OK"], [0, "NÃO"]]}
              coluna={'availablequantity'}>
              Estoque </Dropdown> </th> */}
            <th>Estoque</th>
            {/* <th> <Dropdown filtrar={filtrar} titulo={'Ativo'}
              itens={[[true, "OK"], [false, "NÃO"]]}
              coluna={'active'}>
              Ativo </Dropdown> </th> */}
            <th>Ativo</th>

            {/* <th> <Dropdown filtrar={filtrar} titulo={'Visível'}
              itens={[[true, "OK"], [false, "NÃO"]]}
              coluna={'visible'}>
              Visível </Dropdown> </th> */}
            <th>Visível</th>

          </tr>
        </thead>
        <tbody>
          {dados && dados.length > 0 &&
            dados.map((item) => {
              return <Row row={item} key={item.id} />;
            })}
        </tbody>
      </table>
    </div >
  );
}
