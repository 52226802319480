import React from "react";
import { useState, useEffect } from "react";
import MenuFarolDeskMobile from "../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid } from "@mui/material";
import { defaultRequest, exportarCsv } from "../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import "./acessos.css";
import Row from "./RowAtrasoOfertados";
import HeaderDesk from "../../components/Gestao/HeaderDesk/HeaderDesk";
import ScrollSelect from "../../components/Gestao/ScrollSelect/ScrollSelect";
import { Skeleton } from "@mui/material";

export default function AtrasoOfertados() {
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosTable, setDadosTable] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const [params, setParams] = useState({ filtro_colecao: false, filtro_marca: false });
  const [loading, setLoading] = useState();

  useEffect(() => {

    defaultRequest("/atrasoOfertados", {}).then((ret) => {
      setColecoes(ret.colecoes)
      setDadosOriginais(ret.dados);
      setDadosTable(ret.dados);
    })

  }, []);

  const exportar = async () => { exportarCsv(dadosTable, "ATRASO OFERTADOS"); };

  return (
    <>
      <MenuFarolDeskMobile
        title={
          <HeaderDesk
            atualizado={dadosTable.atualizado}
            setBusca={false}
            setLoading={false}
            tipoTela={"log resumo diário"}
            title={"ATRASO OFERTADOS"}
            enderecoVoltar={"/gestao"}
          />
        }
        body={
          <div className="card-total">
            <div className="divider-pacote" />

            <div className="box-geral resumo-diario">
              <ScrollSelect
                setLoading={setLoading}
                colecoes={colecoes}
                params={params}
                setParams={setParams}
                dadosOriginais={dadosOriginais}
                setDadosTable={setDadosTable}
                tela={"log resumo diário"}
              />
              <button className="btn-exportar-log" onClick={exportar}>
                Exportar
              </button>
            </div>

            {dadosTable.length > 0 ? (
              <>
                <Grid container className="">
                  <table>
                    <thead>
                      <tr className="header-tabela">
                        <th className="th">Referência</th>
                        <th className="th">Coleção</th>
                        <th className="th">Data programada</th>
                        <th className="th">Data ofertada</th>
                        <th className="th">Diferença</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dadosTable.length > 0 && dadosTable.map((iten) => { return <Row row={iten} />; })}
                    </tbody>
                  </table>
                </Grid>
              </>
            ) : (<Skeleton className="skeleton-format" variant="rectangular" height={200} />)
            }
          </div>
        }
      />
    </>
  );
}
