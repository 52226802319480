import * as React from 'react';
import { format_data, formatPrice } from '../../utils/functions';

export default function RowResumoDiario({ row }) {

  const porcentagem = ((row.RECEITA_FILTRADA * 100) / row.RECEITA).toFixed(2);

  return (<>
    <tr>
      <td className=''> {row.COLECAO}</td>
      <td className=''> {format_data(row.DATA)}</td>
      <td className=''> {formatPrice(row.RECEITA)}</td>
      <td className=''> {formatPrice(row.RECEITA_FILTRADA)}</td>
      <td className=''> {porcentagem} %</td>
    </tr>
  </>);
}
